<template>
  <transition name="modal">
    <div class="modal-mask">
    <div class="modal-wrapper">
    <div class="modal-container">

    <div class="modal-header font-weight-bold">
      <slot v-if="exists" name="header">Lead Distribution for {{passedLeadDistribution.user.firstName}} {{passedLeadDistribution.user.lastName}}</slot>
      <slot v-else name="header">Create new Lead Distribution</slot>
    </div>

    <div class="modal-body mt-0 mb-0">
      <form>

        <div v-if="formHasErrors" class="alert alert-danger alert-dismissible fade show" role="alert">
          <strong>Please fill in all data</strong>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="user" class="text-dark">Consultant</label>
          </div>
          <div v-if="!exists" class="form-group col-md-8">
            <select class="custom-select-dark" id="user" v-model="selectedUserId">
              <option
                  v-for="(user) in unusedUsers"
                  :key="user.id"
                  :value="user.id">{{user.firstName}} {{user.lastName}} ({{user.username}})</option>
            </select>
          </div>
          <div v-else class="form-group col-md-8">
            <select disabled class="custom-select-dark" id="user1" v-model="selectedUserId">
              <option :value="this.selectedUserId" selected >{{passedLeadDistribution.user.firstName}} {{passedLeadDistribution.user.lastName}} ({{passedLeadDistribution.user.username}})</option>
            </select>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="states" class="text-dark">States</label>
          </div>
          <div class="form-group col-md-8">
            <treeselect
                v-model="selectedStates"
                :multiple="true"
                :options="treeStates"
                class="custom-select-dark"
                id="states"
                ref="stateTreeselect"
            />
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="states" class="text-dark">Lead Sources</label>
          </div>
          <div class="form-group col-md-8">
            <treeselect
                v-model="selectedLeadSources"
                :multiple="true"
                :options="treeLeadSources"
                class="custom-select-dark"
                id="leadSources"
                ref="leadSourceTreeselect"
            />
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="active" class="text-dark">Active</label>
          </div>
          <div v-if="exists" class="form-group col-md-3">
            <BooleanInputField v-if="isAdmin" :passedData="{name: 'active', index: 0, value: passedLeadDistribution.active}" @input="changeFromComponent"/>
            <label v-if="!isAdmin" class="custom-fg-dark">{{(passedLeadDistribution.active) ? "Yes" : "No"}}</label>
          </div>
          <div v-else class="form-group col-md-3">
            <BooleanInputField v-if="isAdmin" :passedData="{name: 'active', index: 0, value: null}" @input="changeFromComponent"/>
            <label v-if="!isAdmin" class="custom-fg-dark">{{"Yes"}}</label>
          </div>
        </div>
      </form>
    </div>

    <div class="modal-footer">

      <div class="col-auto">
        <div class="container mt-2">
          <div class="row">
            <div class="col">
              <button class="btn btn-primary mt-2" @click.prevent="save()">Save</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <div class="container mt-2">
          <div class="row">
            <div class="col">
              <button class="btn btn-secondary mt-2" @click.prevent="close()">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    </div>
    </div>
    </div>
  </transition>
</template>

<script>

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import BooleanInputField from '@/components/util/BooleanInputField'
import Store from '@/store'

export default {
  name: 'userModal',
  components: {
    Treeselect,
    BooleanInputField
  },
  mounted() {
    if (this.selectedStates !== undefined && this.selectedStates.length > 0) {
      this.selectedStates.forEach(s => {
        this.$refs.stateTreeselect.$emit('select', s)
      })
    }

    if (this.selectedLeadSources !== undefined && this.selectedLeadSources.length > 0) {
      this.selectedLeadSources.forEach(s => {
        this.$refs.leadSourceTreeselect.$emit('select', s)
      })
    }
  },
  computed: {
    isAdmin: function() {
      return (Store.state.userInfo != null) ? Store.state.userInfo.admin : false;
    },
    unusedUsers: function () {
      return this.users.filter(u => !this.leadDistributions.map(ld => ld.user.username).includes(u.username));
    },
    treeStates: function () {
      return this.states.map(s => {
        return {
          id: s.id,
          label: s.shortName
        };
      })
    },
    treeLeadSources: function () {
      return this.leadSources.map(ls => {
        return {
          id: ls.name,
          label: ls.value
        }
      })
    }
  },
  created: async function () {
    if (this.passedLeadDistribution !== null && this.passedLeadDistribution !== undefined) {
      this.selectedStates = this.passedLeadDistribution.availableStates.map(s => s.id);
      this.selectedLeadSources = this.passedLeadDistribution.availableLeadSources;
      this.selectedUserId = this.passedLeadDistribution.user.id;
    }
  },
  data: function () {
    return {
      selectedUserId: {},
      selectedStates: [],
      selectedLeadSources: [],
      active: null,
      formHasErrors: false
    }
  },
  methods: {
    changeFromComponent: function(returnData) {
      this.active = returnData.newValue;
    },
    close: function () {
      this.$emit('close');
    },
    save: function () {
      let filledLeadDistribution = {
        userId: this.selectedUserId,
        availableLeadSources: this.selectedLeadSources,
        availableStates: this.selectedStates,
        active: this.active
      }

      if (this.passedLeadDistribution !== null && this.passedLeadDistribution !== undefined) {
        filledLeadDistribution.id = this.passedLeadDistribution.id;

        if (filledLeadDistribution.active === null || filledLeadDistribution.active === undefined) {
          filledLeadDistribution.active = this.passedLeadDistribution.active;
        }
      }

      if (filledLeadDistribution.userId === null ||
          filledLeadDistribution.userId === undefined ||
          filledLeadDistribution.availableLeadSources === undefined ||
          filledLeadDistribution.availableLeadSources === null ||
          filledLeadDistribution.availableLeadSources <= 0 ||
          filledLeadDistribution.availableStates === undefined ||
          filledLeadDistribution.availableStates === null ||
          filledLeadDistribution.availableStates <= 0 ||
          filledLeadDistribution.active === null ||
          filledLeadDistribution.active === undefined
      ) {
        this.formHasErrors = true;
      }
      else {
        this.formHasErrors = false;

        this.$emit('change', filledLeadDistribution);
        this.$emit('close');
      }
    },
  },
  props: ['exists', 'passedLeadDistribution', 'leadDistributions', 'users', 'states', 'leadSources'],
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 900px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #18752E;
}

.modal-body {
  margin: 20px 0;
  color: #18752E;
}

.modal-footer {
  color: #18752E;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
