<template>

  <div class="container">

    <div id="UserModal">
      <LeadDistributionModal
          v-if="showDetailModal"
          :exists="leadDistributionExists"
          :passedLeadDistribution="selectedLeadDistribution"
          :leadDistributions="leadDistributions"
          :leadSources="leadSources"
          :users="users"
          :states="states"
          @close="close"
          @change="changeFromModal"
      />
    </div>

    <div id="ConfirmBulkUpdateModal">
      <ConfirmModal v-if="showConfirmModal" @confirm="confirmBulkUpdateLeadDistribution()" @cancel="showConfirmModal = false">
        <h3 slot="header">{{confirmModalHeader}}</h3>
        <p slot="body">
          {{confirmModalBody}}
        </p>
      </ConfirmModal>
    </div>

    <p>
      <span>
        [ <router-link :to="{name: 'Applications'}" class="custom-fg-dark">Return to Application Listing</router-link> ]
      </span>
    </p>

    <div class="card mt-4" id="results">

      <div class="card-header">
        <div class="row">
          <div class="col-4">
            <h5>Lead Distribution Administration</h5>
            <small>Double click for full detail</small>
          </div>
          <div class="col-3">
            <input id="filter" placeholder="Filter by Name or Email" type="text" class="form-control" v-model="searchTerm">
          </div>
          <div class="col-2">
            <button @click.prevent="updateLeadDistributionStatusSetActive" type="button" class="mt-1 mr-2 btn btn-outline-light btn-sm">All ON</button>
            <button @click.prevent="updateLeadDistributionStatusSetInactive" type="button" class="mt-1 btn btn-outline-light btn-sm">All OFF</button>
          </div>
          <div v-if="isAdmin" class="col-3" style="text-align:right;">
            <div class="col-auto custom-cursor-pointer mt-2" @click="showLeadDistributionCreateModal()">
              <i class="fa fa-plus-circle customer-fg-dark mr-2" aria-hidden="true" />
              <span>Add Lead Distribution</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-sm table-hover mb-0">
        <thead class="custom-bg-med custom-sticky-header sticky-top">
          <tr>
            <th class="th-lg" scope="col">First Name</th>
            <th class="th-lg" scope="col">Last Name</th>
            <th class="th-lg" scope="col">Username</th>
            <th class="th-xlg" scope="col">States</th>
            <th class="th-xlg" scope="col">Lead Sources</th>
            <th scope="col">Active</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody v-cloak>
          <tr v-for="(user, index) in filteredResult" :key="user.id" v-on:dblclick="showLeadDistributionUpdateModal(user)">
            <td>
              <label class="custom-fg-dark">{{user.user.firstName}}</label>
            </td>
            <td>
              <label class="custom-fg-dark">{{user.user.lastName}}</label>
            </td>
            <td>
              <label class="custom-fg-dark">{{user.user.username}}</label>
            </td>
            <td>
              <label class="custom-fg-dark">{{user.availableStates.map(s => s.shortName).sort().join(', ')}}</label>
            </td>
            <td>
              <label class="custom-fg-dark">{{user.availableLeadSources.join(', ')}}</label>
            </td>
            <td>
              <BooleanInputField
                v-if="isAdmin"
                :key="user.active"
                :passedData="{name: 'active', index: index, value: user.active}"
                @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{formatCurrency(user.active)}}</label>
            </td>
            <td ><i class="fa fa-paperclip customer-fg-dark mr-2 custom-cursor-pointer" aria-hidden="true" @click="confirmAttachApplication(index)"/></td>
          </tr>
        </tbody>
      </table>

    </div>

  </div>

</template>

<script>
import lodash from 'lodash'

import Store from '@/store'

import User from '@/services/UserService'
import LeadDistribution from '@/services/LeadDistributionService'
import State from '@/services/StateService'
import PickDetail from '@/services/PickDetailService'

import LeadDistributionModal from '@/components/modals/LeadDistributionModal'
import ConfirmModal from "@/components/modals/ConfirmModal";

import BooleanInputField from "@/components/util/BooleanInputField";

export default {
  name: 'lead-distributions',
  components: {
    ConfirmModal,
    BooleanInputField,
    LeadDistributionModal
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  },
  computed: {
    isAdmin: function() {
      return (Store.state.userInfo != null) ? Store.state.userInfo.admin : false;
    },
    filteredResult: function() {
      return this.leadDistributions
          .filter(result => {
            return (
              result.user.firstName.toLowerCase().match(this.searchTerm.toLowerCase()) ||
              result.user.lastName.toLowerCase().match(this.searchTerm.toLowerCase()) ||
              result.user.username.toLowerCase().match(this.searchTerm.toLowerCase()) ||
              ((result.user.email !== undefined)
                  ? result.user.email.toLowerCase().match(this.searchTerm.toLowerCase())
                  : false
              )
            );
          }).sort((a, b) => a.user.firstName > b.user.firstName && 1 || -1);
    },
  },
  created: async function () {
    this.users = await User.getUserList();
    this.leadDistributions = await LeadDistribution.getLeadDistributionList();
    this.states = await State.getStates();
    this.leadSources = await PickDetail.getPickListDetail('leadsource')
  },
  data: function () {
    return {
      confirmModalHeader: "",
      confirmModalBody: "",
      bulkUpdateMode: null,
      leadDistributionExists: null,
      leadDistributions: [],
      users: [],
      states: [],
      leadSources: [],
      showConfirmModal: false,
      searchTerm: "",
      showDetailModal: false,
      selectedLeadDistribution: {}
    }
  },
  methods: {
    changeFromComponent: async function(returnData) {
      var name = returnData.name;
      var idx = returnData.index;
      var leadDistribution = this.filteredResult[idx];
      var newValue = returnData.newValue;

      leadDistribution[name] = newValue;

      let leadDistributionUpdate = {
        id: leadDistribution.id,
        userId: leadDistribution.userId,
        user: leadDistribution.user,
        active: leadDistribution.active,
        availableLeadSources: leadDistribution.availableLeadSources,
        availableStates: leadDistribution.availableStates.map(s => s.id)
      }

      this.leadDistributions[idx] = await LeadDistribution.updateLeadDistribution(leadDistributionUpdate);
    },
    close: async function() {
      this.showDetailModal = false;
      this.leadDistributionExists = null;
    },
    changeFromModal: async function(leadDistribution) {
      if (this.leadDistributionExists) {
        var idx = lodash.findIndex(this.filteredResult, {id: leadDistribution.id});
        this.leadDistributions[idx] = await LeadDistribution.updateLeadDistribution(leadDistribution);
      }
      else {
        let newLeadDistribution = await LeadDistribution.createLeadDistribution(leadDistribution);
        this.filteredResult.push(newLeadDistribution);
      }

      this.leadDistributionExists = null;
      this.leadDistributions = await LeadDistribution.getLeadDistributionList();
    },
    showLeadDistributionUpdateModal(user) {
      this.leadDistributionExists = true;
      this.selectedLeadDistribution = user;
      this.showDetailModal = true;
    },
    showLeadDistributionCreateModal() {
      this.leadDistributionExists = false;
      this.selectedLeadDistribution = null;
      this.showDetailModal = true;
    },
    showBulkUpdateLeadDistributionModal: function(active) {
      this.confirmModalHeader = "Please Confirm";

      if (active) {
        this.confirmModalBody = "Please confirm you would like to enable all lead distribution rules";
        this.bulkUpdateMode = 'ACTIVE';
      }
      else {
        this.confirmModalBody = "Please confirm you would like to disable all lead distribution rules";
        this.bulkUpdateMode = 'INACTIVE';
      }

      this.showConfirmModal = true;
    },
    confirmBulkUpdateLeadDistribution: async function () {
      let payload = null;

      switch (this.bulkUpdateMode) {
        case "ACTIVE":
          payload = { active: true }
          break;
        case "INACTIVE":
          payload = { active: false }
          break;
      }

      if (payload !== null) {
        await LeadDistribution.bulkUpdateLeadDistributionStatus(payload);

        this.leadDistributions = await LeadDistribution.getLeadDistributionList();
      }

      this.bulkUpdateMode = null;

      this.showConfirmModal = false;
    },
    updateLeadDistributionStatusSetActive: function () {
      this.showBulkUpdateLeadDistributionModal(true);
    },
    updateLeadDistributionStatusSetInactive: function () {
      this.showBulkUpdateLeadDistributionModal(false);
    }
  }
}
</script>
