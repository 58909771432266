import HTTP from './HttpService'

var LeadDistribution = {

  createLeadDistribution: async function (leadDistribution) {
    let mappedLeadDistribution = {
      id: leadDistribution.id,
      active: leadDistribution.active,
      availableLeadSources: leadDistribution.availableLeadSources,
      availableStateIds: leadDistribution.availableStates
    }

    if (leadDistribution.user !== undefined) {
      mappedLeadDistribution.userId = leadDistribution.user.id
    }
    else {
      mappedLeadDistribution.userId = leadDistribution.userId
    }

    let responseLeadDistribution = {};

    await HTTP.http().post('/leaddistribution/create', mappedLeadDistribution)
      .then(function(result) {
        responseLeadDistribution = result.data;
      })
      .catch(function(error) {
        console.log('Create lead distribution error: ' + error);
      });

    return responseLeadDistribution;
  },

  getLeadDistribution: async function(leadDistributionId) {
    var leadDistribution = {};

    await HTTP.http().get('/leaddistribution/' + leadDistributionId)
      .then(function(response) {
        if (response) {
          leadDistribution = response.data;
        }
      })
      .catch(function(error) {
        console.log('Load lead distribution information error: ' + error);
      });

      return leadDistribution;
  },

  getLeadDistributionList: async function() {
    var leadDistributionList = [];

    await HTTP.http().get('/leaddistribution/')
      .then(function(response) {
        if (response) {
          leadDistributionList = response.data;
        }
      })
      .catch(function(error) {
        console.log('Get lead distribution list error: ' + error);
      });

      return leadDistributionList;
  },

  bulkUpdateLeadDistributionStatus: async function (bulkUpdatePayload) {
    let response = {};

    await HTTP.http().post('/leaddistribution/all/update', bulkUpdatePayload)
      .then(function(result) {
        response = result.data;
      })
      .catch(function(error) {
        console.log('Bulk update lead distribution error: ' + error);
      });

    return response;
  },

  updateLeadDistribution: async function (leadDistribution) {
    let mappedLeadDistribution = {
      id: leadDistribution.id,
      active: leadDistribution.active,
      availableLeadSources: leadDistribution.availableLeadSources,
      availableStateIds: leadDistribution.availableStates
    }

    if (leadDistribution.user !== undefined) {
      mappedLeadDistribution.userId = leadDistribution.user.id
    }
    else {
      mappedLeadDistribution.userId = leadDistribution.userId
    }

    let responseLeadDistribution = {};

    await HTTP.http().post('/leaddistribution/update', mappedLeadDistribution)
      .then(function(result) {
        responseLeadDistribution = result.data;
      })
      .catch(function(error) {
        console.log('Update lead distribution error: ' + error);
      });

      return responseLeadDistribution;
  },

}

export default LeadDistribution;
